import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"


export default function About({ data }) {
  return (
    <div>
      <Layout title="about">
        <section id="about" className="py-3">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h1>What Shanti-Aid Does</h1>
                <p>At Shanti, we want to Empower Women to have an Independent and Confident life. We will take time to listen to you, guide you and support you through your journey.</p>

                <p>We are a group of women who have come together to provide support and guidance to those facing or have experienced domestic violence and abuse.</p>

                <p>We have both professional and life experience to assist women with their journey to greater independence, personal confidence, and a life of safety.</p>

                <p>We give women the time to talk through their experiences, be heard and then to offer support and guidance as need be.</p>

                <p>This could be on how to access a refuge, if they feel it is time to leave their perpetrator/abuser, where we can contact refuges on their behalf to find available places.</p>

                <p>It could be to signpost them to where to claim any benefits they may be entitled to, in order to increase their independence. To assist them in finding any additional professional help as required to facilitate their journey such as solicitors in a variety of fields.</p>

                <p>Our role is to work alongside whoever needs our assistance, sometimes guiding them in what they need to do, at other times to do work on their behalf with whatever agency/service is required.</p>

                <p>We are a team of highly motivated, supportive, and skilled women who will get the best for those who use our service. A great outcome is what we seek for women we have the pleasure of working with.</p>

                <p>We also work with a range of providers to offer training courses that can enhance the skills of the women we support, helping them towards greater independence, confidence and potentially employment. Allowing them to be self-sufficient and lead a life that is both empowering and fulfilling.</p>

                <p>Domestic violence can affect anyone at any time. You could be a victim yourself or know of someone who is suffering, be it your mother, sister, friend or neighbor. It not only affects the victim but also the family and its wider circle. If you’re a child you can feel helpless not knowing where  to turn to  for support. As an adult you have some many questions on how you can make the first move to get on with the rest of your life as you and not a victim.</p>
              </div>
              <div className="col-md-6 ">
              <GatsbyImage image={data.logoimage.childImageSharp.gatsbyImageData} alt="shanti-aid" />
                {/* <img src="img/shanti_aid.png" alt="" className="img-fluid rounded-lg d-none d-md-block about-img shadow p-3 mb-5 bg-white rounded"> */}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>

  )
}


export const query = graphql`
{
  logoimage: file(relativePath: { eq: "shanti_aid.png" }) {
      childImageSharp {
        gatsbyImageData(width: 600)
      }
    }
}    
`